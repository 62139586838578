<script setup>

import {Head} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import {onMounted} from "vue";
import SiteIcon from "@/../assets/Application-logo.svg";
const $q = useQuasar()


onMounted(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('tw-dark');
        $q.dark.set(true);
    }
})

defineProps({
    title: String,
});

</script>

<template>
    <Head :title="title">
        <title class="hidden">{{title}}</title>
        <component :is="'script'" src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></component>
        <link rel="icon" :href=SiteIcon>
        <slot></slot>
    </Head>
</template>

